.page-header {
    box-sizing: border-box;
    height: 72px;
    padding: 0 120px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    .logo-box {
        display: flex;
        align-items: center;
    }
    .title-link-box {
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .title-link {
            display: inline;
            font-size: 16px;
            font-weight: 400;
            cursor: pointer;
            &:not(:last-child) {
                padding-right: 32px;
            }
        }
        .active {
            font-weight: 600;
        }
        .title-btn {
            color: #ffffff;
            background-color: #4060FF;
            padding: 10px 20px;
            border-radius: 80px;
            box-shadow: 0px 2px 20px 0px rgba(64, 96, 255, 0.40);
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px; /* 125% */
        }
    }
}
.dsr-button {
    text-align: center;
    font-style: normal;
    font-weight: 693;
    line-height: 20px; /* 125% */
    letter-spacing: 0.32px;
    background: linear-gradient(90deg, #0696FF 0%, #4060FF 100%);
    box-shadow: 0px 30px 50px 0px rgba(40, 120, 255, 0.30);
    font-size: 14px;
    color: #fff;
    width: 116px;
    height: 32px;
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-shrink: 0; 
    img {
        margin-left: 8px;
    }
}

.dsr-container {
    display: flex;
    align-items: center;
    overflow: hidden;
}
.dsr-title {
color: #FFF;
font-family: "Alimama ShuHeiTi";
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.24px;
margin-right: 12px;
}
.dsr-subTitle {
color: #FFF;
font-family: "PingFang SC";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 170%; /* 20.4px */
margin-top: 1px;
}
@media (max-width: 768px) {
    .dsr-title {
        font-size: 18px;
    }
    
    .dsr-subTitle {
        font-size: 10px;
    }
    
    .dsr-button {
        width: 90px;
        font-size: 12px;
    }
}

@media (max-width: 480px) {
    .dsr-title {
        font-size: 16px;
    }
    
    .dsr-container svg {
        width: 20px;
        height: 25px;
    }
}